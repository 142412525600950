import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';

import App from './components/App.js'

ReactDOM.render (
    <App />,
    document.getElementById('root')
)
