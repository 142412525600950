import React, { Component } from 'react';

class LargeTitle extends Component {
    render() {
        return (
            <h1 className='LargeTitle'>RemindMe</h1>
        )
    }
}

export default LargeTitle;
